import React from "react";
import logo from "./images/logo.png";
import App from "./App";
import ReactDOM from "react-dom";

function SetFormato(valor = 0){
  return new Intl.NumberFormat('es-MX',{style:'currency',currency:'MXN'}).format(valor) + "MXN"
}

function round(num) {
  var m = Number((Math.abs(num) * 100).toPrecision(15));
  return Math.round(m) / 100 * Math.sign(num);
}

function currencyFormatter({ currency, value}) {
  const formatter = new Intl.NumberFormat()
  return formatter.format(value)
}


export default class Appereance extends React.Component{

  constructor(props) {
    super();
    this.state = {showForm:false}
  }

  componentDidMount() {
    this.setDescription();
    this.setAmount();
    this.setRetribution();
    this.setSuccess();
    this.setPrefijo();

    this.showListeners();
  }

  setDescription(){
    //colocamos la descripcion que obtivimos del get desc
    document.getElementById("description").textContent = this.getParameterByName("desc")
  }

  setAmount(){
    //colocamos la cantidad de dinero que cuesta un boleto de acierto al get amount
    document.querySelector("h1 .cantidad").textContent = SetFormato(this.getParameterByName("amount"))
    document.querySelector("h1 .cantidad").setAttribute("value",this.getParameterByName("amount")+".00")
  }

  setRetribution(){
    var cantidad = Number(this.getParameterByName("amount"));
    var boletos = document.querySelector("select").value
    var subtotal = boletos * cantidad;

    var comision = (subtotal*.036) + 3
    var impuestos = round(comision * .16)

    var total = round(comision + impuestos);

    document.querySelector(".cantidad-recuperacion").textContent = total;
  }

  setSuccess(){
    document.querySelector(".success").textContent = this.getParameterByName("success")
  }

  setPrefijo(){
    document.querySelector(".prefijo").textContent = this.getParameterByName("prefijo")
    document.querySelector("#prefijo_asientos").textContent = "Cantidad de " + this.getParameterByName("prefijo") + " :"
  }



  handleChange(evt){
    var cantidad = Number(this.getParameterByName("amount"))
    var boletos = evt.target.value;
    var total = cantidad * boletos


    document.querySelector(".cantidad").textContent = SetFormato(total)
    document.querySelector(".cantidad").setAttribute("value",total)

    this.setRetribution()

    document.querySelector(".checbox-retribucion").checked = false


  }

/*___________________________________*/




  getParameterByName(name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
  }

  showListeners(){
    if(document.getElementById("input_cantidad")){
      document.getElementById("input_cantidad").addEventListener('keyup',evt=>{
        var valor =  Number(document.getElementById("input_cantidad").value+".00");
        document.querySelector(".check-container").style.display = "flex"
        document.querySelector(".cantidad-recuperacion").textContent =
            ( Number((valor*.036).toFixed(2)) + 3
                + Number((valor*0.00815).toFixed(2))).toFixed(2)
        evt.target.setAttribute("content",evt.target.value)
      })
    }
  }



  handleClick(evt){

    var bandera = true;

    document.querySelectorAll(".datas-container input,.datas-container select").forEach(element=>{
      if (element.classList.contains("is-invalid")){
        bandera = false;
      }
    })

    if (!bandera){
      alert("Algunos elementos no han sido llenados correctamente, por favor verifica que todos los campos son de color verde e intentalo de nuevo.")
    }else{
      var cantidad = document.querySelector(".cantidad").textContent;
      cantidad = cantidad.replaceAll(" MXN","")
      cantidad = cantidad.replaceAll(",","")
      cantidad = cantidad.replaceAll("$","")
      cantidad = cantidad.replace(".00","")

      let formData = new FormData()

      formData.append("firstname", document.getElementById("nombre").value);
      formData.append("email",document.getElementById("correo").value);
      formData.append("phone",document.getElementById("telefono").value)
      formData.append("61",cantidad);
      formData.append("111",cantidad);

      let parameters = new Appereance();

      if(parameters.getParameterByName("r_utm_campaign") != null){
        formData.append("71",parameters.getParameterByName("r_utm_campaign"));
      }else{
        if(parameters.getParameterByName("utm_campaign") != null){
          formData.append("71",parameters.getParameterByName("utm_campaign"));
        }
      }

      if(parameters.getParameterByName("r_tipo_de_donante") !== null){
        formData.append("41",parameters.getParameterByName("r_tipo_de_donante"));
      }else{
        if(parameters.getParameterByName("tipo_de_donante") !== null){
          formData.append("41",parameters.getParameterByName("tipo_de_donante"));
        }
      }

      if(parameters.getParameterByName("r_utm_source") != null){
        formData.append("72",parameters.getParameterByName("r_utm_source"));
      }else{
        if(parameters.getParameterByName("utm_source") != null){
          formData.append("72",parameters.getParameterByName("utm_source"));
        }
      }

      if(parameters.getParameterByName("utm_content") != null){
        formData.append("74",parameters.getParameterByName("utm_content"));
      }

      if(parameters.getParameterByName("r_utm_term") != null){
        formData.append("101",parameters.getParameterByName("r_utm_term"));
      }else{
        if(parameters.getParameterByName("utm_term") != null){
          formData.append("101",parameters.getParameterByName("utm_term"));
        }
      }

      if(parameters.getParameterByName("r_utm_medium") != null){
        formData.append("73",parameters.getParameterByName("r_utm_medium"));
      }else{
        if(parameters.getParameterByName("utm_medium") != null){
          formData.append("73",parameters.getParameterByName("utm_medium"));
        }
      }


      fetch("https://api.savethechildrenmx.org/save_contact.php",{
        method: 'POST',
        body: formData
      }).then(resp=>{
        if(resp.status === 200){
          ReactDOM.render(<App/>, document.querySelector(".app-container"));
          document.querySelector(".app-container").style.display = "flex"
          document.querySelector(".datas-container").style.display = "none"
          document.querySelector(".datas-container").nextElementSibling.style.display = "none"
        }else{
          console.error(resp.statusText)
        }
      })
    }

  }

  verificaFormato(){
    var cantidad = document.querySelector(".cantidad").textContent
    cantidad = cantidad.replace(" MXN","")
    cantidad = cantidad.split(".")
    if (cantidad[1].length == 1){
      document.querySelector(".cantidad").textContent = cantidad[0] + "." + cantidad[1]+"0 MXN"
    }else{
      if (cantidad[1].length == 0){
        document.querySelector(".cantidad").textContent = cantidad[0] + "." + cantidad[1]+"00 MXN"
      }
    }
  }

  changeAmount(evt){
    var cantidad,total;

    if(evt.target.checked){
      var newAmount =Number(document.querySelector(".cantidad").getAttribute("value")) +
        Number(document.querySelector(".cantidad-recuperacion").textContent);
      document.querySelector(".cantidad").textContent = SetFormato(newAmount)
    }else{
      document.querySelector(".cantidad").textContent = SetFormato(document.querySelector(".cantidad").getAttribute("value"))
    }


  }

  changeAmountTickets(evt){
    if(evt.target.value.length > 0){
      document.querySelector(".cantidad").textContent = new Intl.NumberFormat().format(Number(document.querySelector(".cantidad").getAttribute("value").replace(",","")) * Number(evt.target.value))+".00"
    }else{
      evt.target.value = 1
      document.querySelector(".cantidad").textContent = new Intl.NumberFormat().format(
        document.querySelector(".cantidad").getAttribute("value")
      )+".00"
    }

    var valor =  Number(document.querySelector(".cantidad").getAttribute("value")) * Number(evt.target.value);
    document.querySelector(".cantidad-recuperacion").textContent = new Intl.NumberFormat().format(
      Number((valor*.036)+3+(valor*0.00815))
    )

    var dato = document.querySelector(".cantidad-recuperacion").textContent

    if (dato.includes(".")){
      var datos = dato.split(".")
      if(datos[1].length>2){
        document.querySelector(".cantidad-recuperacion").textContent = datos[0]+"."+datos[1].substr(0,2)
      }else{
        if(datos[1].length==1){
          document.querySelector(".cantidad-recuperacion").textContent = dato+"0"
        }else{
          if(datos[1].length==0) {
            document.querySelector(".cantidad-recuperacion").textContent = dato + "00"
          }
        }
      }
    }

  }

  handleNombre(evt){
    var regex = new RegExp("^[A-Za-záéíóúÁÉÍÓÚÑñ]+(\ [A-Za-záéíóúÁÉÍÓÚÑñ]+){2,}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
    }

    var flag = true;


    evt.target.parentElement.parentElement.querySelectorAll(".form-group input").forEach(element=>{
      if(element.classList.contains("is-invalid")){
        flag = false;
      }
    })

    if (flag){
      document.querySelector(".button-continuar").style.display= "flex"
    }
  }

  handleCorreo(evt){
    var regex = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@”]+(\\.[^<>()\\[\\]\\\\.,;:\\s@”]+)*)|(“.+”))@((\\[[0–9]{1,3}\\.[0–9]{1,3}\\.[0–9]{1,3}\\.[0–9]{1,3}])|(([a-zA-Z\\-0–9]+\\.)+[a-zA-Z]{2,}))$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
    }

    var flag = true;


    evt.target.parentElement.parentElement.querySelectorAll(".form-group input").forEach(element=>{
      if(element.classList.contains("is-invalid")){
        flag = false;
      }
    })

    if (flag){
      document.querySelector(".button-continuar").style.display= "flex"
    }
  }

  handleTelefono(evt){
    var regex = new RegExp("^[0-9]{10}$");
    if(regex.test(evt.target.value)){
      evt.target.classList.add("is-valid")
      evt.target.classList.remove("is-invalid")
    }else{
      evt.target.classList.remove("is-valid")
      evt.target.classList.add("is-invalid")
    }

    var letras = new RegExp("[A-ZÑÁÉÍÓÚa-zñáéíóú\[\\\^\$\.\|\\-\\_\?\*\+\(\)\{\}]");

    if(letras.exec(evt.target.value) != null){
      evt.target.value = evt.target.value.substr(0,evt.target.value.length-1)
    }
  }

  render() {
    return (
      <div className="checkout-container">
        <div className="information-container">
          <img src={logo} alt="stc-logo"/>
          <div className="label-container">
            <h3>Comprar boleto a:</h3>
            <h2>
              FUNDACION MEXICANA DE APOYO INFANTIL AC
            </h2>
            <h3>Para</h3>
            <h2 id="description">
            </h2>
            <h3>Por la cantidad de</h3>
            <div className="cantidad_container">
              <h1><div className="cantidad">200</div></h1>
            </div>
            <div className="success"></div>
            <div className="prefijo"></div>
            <div className="datas-container">
              <div className="form-group">
                <label htmlFor="">Por favor escribe tu nombre y 2 apellidos:</label>
                <input type="text" id="nombre" onKeyUp={this.handleNombre} className="is-invalid"/>
              </div>
              <div className="form-group">
                <label htmlFor="">Número telefónico a 10 dígitos:</label>
                <input type="text" id="telefono" onKeyUp={this.handleTelefono} className="is-invalid" inputMode="numeric"/>
              </div>
              <div className="form-group">
                <label htmlFor="">Correo electrónico:</label>
                <input type="email" id="correo" onKeyUp={this.handleCorreo} className="is-invalid"/>
              </div>
              <div className="form-group">
                <label htmlFor="" id="prefijo_asientos">:</label>
                <select name="" id="lugares" className="is-valid" onChange={this.handleChange.bind(this)}>
                  <option value="1" selected>1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="check-container">
                <input type="checkbox" className="checbox-retribucion" onChange={this.changeAmount.bind(this)}/>
                <label htmlFor="">Deseo agregar <span className="cantidad-recuperacion"></span> a mi compra para ayudar a compensar las comisiones por procesamiento. </label>
              </div>
            </div>


            <button onClick={this.handleClick} className="button-continuar">Continuar</button>
          </div>
        </div>

        <div className="app-container">

        </div>
      </div>
    );
  }
}