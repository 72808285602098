import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./App.css";


function getParameterByName(name) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, " "));
}

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_live_51JTEiIBD2DbjrYps5u9tPodjgr6fvjbrosT16Ri9XcBYfn1NBjVD4oMkrLTXkJoWXIh8MYgsh53OnGaCQQ42S5hB00MMSUwax9");
//const stripePromise = loadStripe("pk_test_51JTEiIBD2DbjrYpsVxW3guUNABhHXcxYAZqChkxQ5dlCLOaBLfXoEQGgj9FY41rQ5PNUkUhpohItApdMRYJVKaHJ00m9YvnQvR");

export default function App() {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    var amount;

    amount = document.querySelector(".cantidad").textContent
    amount = amount.replace(",", "")
    amount = amount.replace("MXN","")
    amount = amount.replace(".","")
    amount = amount.replace("$","")

    var description =
            document.getElementById("description").textContent +
      "( "+ document.querySelector("#lugares").value +
      " " + document.querySelector(".prefijo").textContent+" -" +
      document.querySelector(".cantidad").textContent+" )"

    var nombre = document.getElementById("nombre").value
    var correo = document.getElementById("correo").value
    var phone = document.getElementById("telefono").value

console.log("/create?desc="+description+"&amount="+amount+"&nombre="+nombre+"&correo="+correo+"&phone="+phone)
    fetch("/create.php?desc="+description+"&amount="+amount+"&nombre="+nombre+"&correo="+correo+"&phone="+phone, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
      })
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
}